@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply text-white;
}

select,
input {
  @apply text-black;
}

.centerText {
  text-align: center;
}

#myHand:first-child {
  margin-left: 0 !important;
}
